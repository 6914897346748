<template>
  <div id="conteudo" class="row mx-0 align-items-center">
    <div class="row mx-0 justify-content-evenly">
      <div v-for="(act, a) in actions" :key="`action-${a}`" class="col-12 col-md-6 col-lg-4 my-2">
        <div class="card h-100 p-3">
          <h3 class="text-center"><u> {{ act.title }}</u>:</h3>
          <b-icon :icon="act.icon.model" font-scale="6" :class="act.icon.style" class="mx-auto my-3" />
          <div v-for="(link, l) in act.links" :key="`link-${l}-${a}`" class="d-flex my-2 px-3 align-items-center">
            <b-icon :icon="link.icon" font-scale="1.5" class="mx-3 text-gray" />
            <router-link :to="link.nav"><strong>{{ link.title }}</strong></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        actions: [{
          title: 'Noticías',
          icon: {
            model: 'newspaper',
            style: 'text-warning'
          },
          links: [{
            icon: 'clipboard-plus',
            nav: '/noticias/posts/criar',
            title: 'Criar Novo'
          }, {
            icon: 'card-list',
            nav: '/noticias/posts',
            title: 'Ver Todas'
          }]
        }, {
          title: 'Usuários',
          icon: {
            model: 'file-person',
            style: 'text-success'
          },
          links: [{
            icon: 'person-plus-fill',
            nav: '/noticias/usuario/criar',
            title: 'Criar Novo'
          }, {
            icon: 'person-lines-fill',
            nav: '/noticias/usuarios',
            title: 'Ver Todos'
          }]
        }]
      }
    },
    beforeMount() {
      this.$store.commit('SET_FILTER_POST', false)
    },
  }
</script>

<style scoped>
  .card {
    transition: all 0.3s;
  }

  .card:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 768px) {
    svg.b-icon:not(.text-gray) {
      font-size: 300% !important;
    }
  }
</style>